<script>
import commonMixin from './base/mixins/gcommon.js'
import { createSize } from './base/factory.js'

export default {
  name: 'GMapScale',
  mixins: [commonMixin('control')],
  props: {
    anchor: {
      type: String,
      default: () => {}
    },
    offset: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    anchor() {
      this.reload()
    },
    offset() {
      this.reload()
    }
  },
  methods: {
    load() {
      const { BMapGL, map, anchor, offset } = this
      this.originInstance = new BMapGL.ScaleControl({
        anchor: global[anchor],
        offset: offset && createSize(BMapGL, offset)
      })
      map.addControl(this.originInstance)
    }
  }
}
</script>
