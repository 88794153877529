<template>
  <a-drawer width="100%" :label-col="4" :wrapper-col="14" :visible="open" :closable="false">
    <div class="lane">
      <!-- GL版本 -->
      <!-- <g-map
        class="bm-view"
        :center="center"
        :zoom="zoom"
        @ready="initMap"
        :continuous-zoom="true"
        :scroll-wheel-zoom="true"
      >
        <g-map-scale anchor="BMAP_ANCHOR_TOP_LEFT" :offset="{ width: 30, height: 15 }" />
        <g-map-zoom anchor="BMAP_ANCHOR_TOP_LEFT" :offset="{ width: 30, height: 50 }" />
        <div v-for="item in laneData" :key="item.id">
          <g-map-polyline
            :path="item.baiduPoints"
            :stroke-color="strokeColorObj[item.laneId]"
            :stroke-opacity="0.5"
            :stroke-weight="2"
            :editing="false"
            @click="handleLaneId($event, item.laneId)"
            @mouseover="handleMouseover($event, item.laneId)"
            @mouseout="handleMouseout($event, item.laneId)"
          />
        </div>
      </g-map> -->
      <!-- API2.0版本 -->
      <b-map-base class="bm-view" :center="center" :zoom="zoom" @ready="initMap" :continuous-zoom="true" :scroll-wheel-zoom="true">
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" type="BMAP_NAVIGATION_CONTROL_ZOOM" show-zoom-info="true" />
        <div v-for="item in laneData" :key="item.id">
          <b-map-polyline
            :path="item.baiduPoints"
            :stroke-color="strokeColorObj[item.laneId]"
            :stroke-opacity="0.5"
            :stroke-weight="3"
            :editing="false"
            @click="handleLaneId($event, item.laneId)"
            @mouseover="handleMouseover($event, item.laneId)"
            @mouseout="handleMouseout($event, item.laneId)"
            :icons="icons"
          />
        </div>
      </b-map-base>

      <route-plan-modal
        ref="routePlanRef"
        v-if="routePlanVisible"
        :vehicleId="vehicleId"
        :routeId="curRouteId"
        :mapLaneId="mapLaneId"
        :position="{ top: '4%', left: '52%' }"
        @closeRoutePlanModal="handleCloseRoutePlanModal"
        @UpdateRouteColor="handleUpdateRouteColor"
        @onDeleteLaneId="handleDeleteLaneId"
        @onMapIdChange="handleMapIdChange"
        @onMapVersionChange="handleMapVersionChange"
      />
    </div>
  </a-drawer>
</template>

<script>
import { getLanes } from '@/api/iot/raLane'
import { mapGetters } from 'vuex'
import GMap from '@/components/Map/GMap'
import GMapPolyline from '@/components/Map/GMapPolyline'
import GMapScale from '@/components/Map/GMapScale'
import GMapZoom from '@/components/Map/GMapZoom'
import RoutePlanModal from './RoutePlanModal'

import BMapBase from '@/components/Map/BMapBase'
import BMapPolyline from '@/components/Map/BMapPolyline'
import BMapScale from '@/components/Map/BMapScale'
import BMapZoom from '@/components/Map/BMapZoom'
import mapbase from '@/components/Map/base/mapbase'
export default {
  name: 'CreateForm',
  mixins: [mapbase],
  props: {},
  components: { GMap, GMapPolyline, RoutePlanModal, GMapScale, GMapZoom, BMapBase, BMapPolyline, BMapScale, BMapZoom },
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        laneName: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {},
      center: { lng: 0, lat: 0 },
      zoom: 3,
      laneData: [],
      // 地图是否加载完成标志
      mapIsLoad: false,
      routePlanVisible: false,
      curRouteId: '',
      mapLaneId: '',
      strokeColorObj: {},
      defaultLineColor: 'blue',
      mouseOverLineColor: 'red',
      // 已选中的laneId
      selectedLaneIds: {},
      // 路线列表数据是否加载完成
      routeDataLoading: false,
      markerImageUrl: require('@/assets/images/map/marker_red.png'),
      markers: []
    }
  },
  filters: {},
  created() {},
  computed: {
    ...mapGetters(['userTenant'])
  },
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.open = true
      this.formType = 1
      this.routePlanVisible = true
      this.selectedLaneIds = {}
      this.curRouteId = ''
      this.form = {
        laneName: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formTitle = '添加'
      this.toGetLanes()
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.curRouteId = row.id
      this.formType = 2
      this.toGetLanes()
    },
    toGetLanes(mapId, mapVersion) {
      getLanes({
        mapId: mapId,
        mapVersion: mapVersion
      }).then((res) => {
        this.setMapData(res)
      })
    },
    setMapData(res) {
      if (res.length === 0) {
        return
      }
      if (!this.mapIsLoad) {
        setTimeout(() => {
          this.setMapData(res)
        }, 100)
      } else {
        setTimeout(() => {
          this.laneData = res
          console.log('init')
          this.strokeColorObj = {}
          this.laneData.forEach((p) => {
            this.strokeColorObj[p.laneId] = this.defaultLineColor
          })
          this.$forceUpdate()
          this.routeDataLoading = true
          if (res && res.length > 0) {
            const baiduPoints = res[0].baiduPoints
            const point = new this.BMap.Point(baiduPoints[0].lng, baiduPoints[0].lat)
            this.map.panTo(point)
          }
        }, 300)
      }
    },

    // 生成从minNum到maxNum的随机数
    randomNum(minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return parseInt(Math.random() * minNum + 1, 10)
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
        default:
          return 0
      }
    },

    initMap(obj) {
      const { BMap, map } = obj
      this.BMap = BMap
      this.map = map
      this.zoom = 19
      this.mapIsLoad = true
      this.routeDataLoading = false
      this.center.lng = this.userTenant.lng
      this.center.lat = this.userTenant.lat
    },
    handleLaneId(e, laneId) {
      console.log('handleLaneId', JSON.stringify(e.point))
      this.$refs.routePlanRef.handleAddRoutePlan(laneId)
      // this.mapLaneId = laneId
      this.selectedLaneIds[laneId] = true
    },
    handleCloseRoutePlanModal() {
      this.open = false
      this.routePlanVisible = false
      this.$emit('ok')
    },
    handleMouseover(e, laneId) {
      this.strokeColorObj[laneId] = this.mouseOverLineColor
      this.$forceUpdate()
    },
    handleMouseout(e, laneId) {
      if (this.selectedLaneIds[laneId]) {
        return
      }
      this.strokeColorObj[laneId] = this.defaultLineColor
      this.$forceUpdate()
    },
    handleDeleteLaneId(laneId) {
      console.log('handleDeleteLaneId', laneId)
      delete this.selectedLaneIds[laneId]
      this.strokeColorObj[laneId] = this.defaultLineColor
      this.$forceUpdate()
    },
    handleUpdateRouteColor(data) {
      console.log('handleUpdateRouteColor')
      this.doUpdateRouteColor(data)
    },
    doUpdateRouteColor(data) {
      if (this.routeDataLoading) {
        data.forEach((p) => {
          this.selectedLaneIds[p.laneId] = true
          this.strokeColorObj[p.laneId] = this.mouseOverLineColor
        })
        this.routeDataLoading = false
        this.$forceUpdate()
      } else {
        setTimeout(() => {
          this.doUpdateRouteColor(data)
        }, 200)
      }
    },
    handleMarkerClick(e) {
      const laneId = e.target.K.title
      // this.mapLaneId = laneId
      this.$refs.routePlanRef.handleAddRoutePlan(laneId)
      this.selectedLaneIds[laneId] = true
    },
    handleMapIdChange(mapId, mapVersion) {
      this.toGetLanes(mapId, mapVersion)
    },
    handleMapVersionChange(mapId, mapVersion) {
      this.toGetLanes(mapId, mapVersion)
    }
  }
}
</script>

<style lang="less">
.lane {
  width: 100%;
  height: calc(100vh - 55px);
  margin-bottom: 30px;
  .bm-view {
    width: 100%;
    height: 100%;
    border: 1px solid #013a07;
  }
}
.bottom-control {
  z-index: 99999 !important;
}

.radar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: green;
  position: relative;
  .ripple {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid red;
    animation: ripple 2s linear infinite;
  }
}
@keyframes ripple {
  to {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}
.radar :nth-child(1) {
  animation-delay: 0.666s;
}
.radar :nth-child(2) {
  animation-delay: 1.322s;
}
</style>
