<script>
import commonMixin from './base/mixins/common.js'
import { createSize } from './base/factory.js'

export default {
  name: 'BMapZoom',
  mixins: [commonMixin('control')],
  props: {
    anchor: {
      type: String,
      default: () => {}
    },
    offset: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    anchor() {
      this.reload()
    },
    offset() {
      this.reload()
    }
  },
  methods: {
    load() {
      console.log('scale')
      const { BMap, map, anchor, offset } = this
      this.originInstance = new BMap.ZoomControl({
        anchor: global[anchor],
        offset: offset && createSize(BMap, offset)
      })
      map.addControl(this.originInstance)
    }
  }
}
</script>
