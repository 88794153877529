<template>
  <div>
    <div v-if="!hasBmView" ref="view" style="width: 100%; height: 100%"></div>
    <slot></slot>
  </div>
</template>

<script>
import bindEvents from './base/bindEvent.js'
import { checkType } from './base/util.js'

export default {
  name: 'GMap',
  props: {
    // 百度地图密钥
    ak: {
      type: String,
      default: 'KjLHEgMNYreevztzkmejRXdGSBDlFNue'
    },
    // 中心坐标
    center: {
      type: [Object, String],
      default: () => {}
    },
    // 地图显示大小级别
    zoom: {
      type: Number,
      default: () => {}
    },
    // 最小级别
    minZoom: {
      type: Number,
      default: () => {}
    },
    maxZoom: {
      type: Number,
      default: () => {}
    },
    highResolution: {
      type: Boolean,
      default: true
    },
    // 单击事件
    mapClick: {
      type: Boolean,
      default: true
    },
    rotateGestures: {
      type: Boolean,
      default: true
    },
    tiltGestures: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    center: {
      handler(val, oldVal) {
        console.log('center')
        const { map, zoom } = this
        // 为什么只能设置一次？ todo...
        map.centerAndZoom(val, zoom)
      },
      deep: true
    },
    zoom(val, oldVal) {
      console.log('zoom')
      const { map } = this
      if (val !== oldVal && val >= 3 && val <= 99) {
        map.setZoom(val)
      }
    }
  },
  methods: {
    // 基础参数设置
    setMapOptions() {
      const { map, minZoom, maxZoom } = this
      minZoom && map.setMinZoom(minZoom)
      maxZoom && map.setMaxZoom(maxZoom)
    },
    getCenterPoint() {
      const { center, BMapGL } = this
      switch (checkType(center)) {
        case 'String':
          return center
        case 'Object':
          return new BMapGL.Point(center.lng, center.lat)
        default:
          return new BMapGL.Point()
      }
    },
    // 创建BMapGL地图实例
    init(BMapGL) {
      this.BMapGL = BMapGL
      let $el = this.$refs.view
      for (const $node of this.$slots.default || []) {
        if ($node.componentOptions && $node.componentOptions.tag === 'bm-view') {
          $el = $node.elm
        }
      }
      const map = new BMapGL.Map($el, { enableHighResolution: this.highResolution, enableMapClick: this.mapClick })
      map.enableScrollWheelZoom()
      const { setMapOptions } = this
      // 基础参数设置
      setMapOptions()
      this.map = map
      bindEvents.call(this, map)
      this.$emit('ready', { BMapGL, map })
    },
    initMap(BMapGL) {
      this.BMapGL = BMapGL
      this.init(BMapGL)
    },
    getMapScript() {
      if (!global.BMapGL) {
        const ak = this.ak || this._BMap().ak
        global.BMapGL = {}
        global.BMapGL._preloader = new Promise((resolve, reject) => {
          // URL加载完成之后会调用这个方法_initBaiduMap，同时也会删除这里添加的script，因为返回值会自动加入一条脚本URL
          global._initBaiduMap = function() {
            resolve(global.BMapGL)
            global.document.body.removeChild($script)
            global.BMapGL._preloader = null
            global._initBaiduMap = null
          }
          const $script = document.createElement('script')
          global.document.body.appendChild($script)
          $script.src = `//api.map.baidu.com/api?type=webgl&v=1.0&ak=${ak}&callback=_initBaiduMap`
        })
        return global.BMapGL._preloader
      } else if (!global.BMapGL._preloader) {
        return Promise.resolve(global.MyBMapGL)
      } else {
        return global.BMapGL._preloader
      }
    },
    reset() {
      const { getMapScript, initMap } = this
      getMapScript().then(initMap)
    }
  },
  mounted() {
    this.reset()
  },
  data() {
    return {
      hasBmView: false
    }
  }
}
</script>
